import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';

const Contact = () => {
  const { darkMode } = useContext(ThemeContext);

  const themeClasses = {
    text: darkMode ? 'text-white' : 'text-gray-800',
    subText: darkMode ? 'text-gray-300' : 'text-gray-600',
    cardBg: darkMode ? 'bg-gray-800' : 'bg-white',
    border: darkMode ? 'border-gray-700' : 'border-gray-300',
    inputBg: darkMode ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800',
    focusRing: 'focus:ring-indigo-500',
    buttonBg: 'bg-indigo-600 hover:bg-indigo-700 text-white',
  };

  return (
    <section id="contact" className="py-24 lg:px-24">
      <div className="container mx-auto px-5 flex flex-col items-center">
        <div className="flex flex-col lg:flex-row items-start lg:space-x-12">
          {/* Informative Column */}
          <div className="lg:w-1/2 w-full mb-12 lg:mb-0">
            <h2 className={`text-4xl font-bold mb-6 ${themeClasses.text}`}>Connect with Us</h2>
            <p className={`text-xl mb-6 ${themeClasses.subText}`}>
              Discover how K8sman can transform your Kubernetes operations. Reach out to our team for a personalized consultation.
            </p>
            <ul className={`list-disc pl-5 space-y-3 ${themeClasses.subText}`}>
              <li>Tailored solutions for your organization</li>
              <li>Expert guidance from our DevOps specialists</li>
              <li>Commitment to your success and security</li>
            </ul>
          </div>

          {/* Contact Form Column */}
          <div className="lg:w-1/2 w-full">
            <div className={`p-8 rounded-lg shadow-lg ${themeClasses.cardBg}`}>
              <h2 className={`text-2xl font-semibold mb-4 ${themeClasses.text}`}>Get in Touch</h2>
              <p className={`mb-6 ${themeClasses.subText}`}>
                Complete the form below, and a member of our team will contact you shortly.
              </p>
              <form>
                <div className="mb-4">
                  <label htmlFor="name" className={`block text-sm mb-1 ${themeClasses.subText}`}>
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className={`w-full px-4 py-2 rounded-lg border ${themeClasses.border} ${themeClasses.inputBg} focus:outline-none focus:ring-2 ${themeClasses.focusRing}`}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className={`block text-sm mb-1 ${themeClasses.subText}`}>
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className={`w-full px-4 py-2 rounded-lg border ${themeClasses.border} ${themeClasses.inputBg} focus:outline-none focus:ring-2 ${themeClasses.focusRing}`}
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className={`block text-sm mb-1 ${themeClasses.subText}`}>
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    className={`w-full px-4 py-2 rounded-lg border ${themeClasses.border} ${themeClasses.inputBg} focus:outline-none focus:ring-2 ${themeClasses.focusRing}`}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className={`w-full py-3 rounded-lg font-medium ${themeClasses.buttonBg}`}
                >
                  Submit
                </button>
                <p className={`text-xs mt-4 ${themeClasses.subText}`}>
                  We respect your privacy. Your information is safe with us.
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
