import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';

const PrivacyPolicy = () => {
  const { darkMode } = useContext(ThemeContext);

  const themeClasses = {
    heading: 'text-3xl font-bold mb-6',
    subheading: 'text-2xl font-semibold mt-6 mb-4',
    text: darkMode ? 'text-gray-300' : 'text-gray-700',
    link: 'text-indigo-500 hover:text-indigo-600',
  };

  return (
    <div className={`px-4 md:px-0 ${themeClasses.text}`}>
      <h1 className={`${themeClasses.heading}`}>Privacy Policy</h1>

      <p className="mb-4">
        At K8sman, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you visit our platform or use our services.
      </p>

      <p className="mb-4">
        By accessing or using our services, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.
      </p>

      <h2 className={`${themeClasses.subheading}`}>1. Information We Collect</h2>

      <h3 className="text-xl font-semibold mt-4 mb-2">1.1 Personal Data</h3>
      <p className="mb-4">
        We may collect personally identifiable information ("Personal Data") such as your name, email address, phone number, and billing information when you register for an account, subscribe to our services, or contact us.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">1.2 Usage Data</h3>
      <p className="mb-4">
        We may collect information that your browser sends whenever you visit our platform or access our services ("Usage Data"). This may include your IP address, browser type, pages visited, time and date of your visit, and other diagnostic data.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">1.3 Cookies and Tracking Technologies</h3>
      <p className="mb-4">
        We use cookies and similar tracking technologies to track the activity on our platform and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
      </p>

      <h2 className={`${themeClasses.subheading}`}>2. How We Use Your Information</h2>

      <p className="mb-4">
        We use the collected data for various purposes, including:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>To provide and maintain our services.</li>
        <li>To process transactions and send related information.</li>
        <li>To communicate with you, including responding to your comments, questions, and requests.</li>
        <li>To send you technical notices, updates, security alerts, and support messages.</li>
        <li>To monitor and analyze usage and trends to improve user experience.</li>
        <li>To detect, prevent, and address technical issues and security breaches.</li>
      </ul>

      <h2 className={`${themeClasses.subheading}`}>3. Disclosure of Your Information</h2>

      <p className="mb-4">
        We may disclose your personal information in the following circumstances:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li><strong>Legal Requirements:</strong> If required to do so by law or in response to valid requests by public authorities.</li>
        <li><strong>Business Transactions:</strong> In connection with or during negotiations of any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
        <li><strong>Third-Party Service Providers:</strong> We may share your information with third-party vendors who perform services on our behalf.</li>
      </ul>

      <h2 className={`${themeClasses.subheading}`}>4. Data Security</h2>

      <p className="mb-4">
        We implement reasonable security measures to protect your personal information. However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure.
      </p>

      <h2 className={`${themeClasses.subheading}`}>5. Your Rights and Choices</h2>

      <h3 className="text-xl font-semibold mt-4 mb-2">5.1 Access and Correction</h3>
      <p className="mb-4">
        You have the right to access and update your personal information. You can review and change your personal information by logging into your account settings.
      </p>

      <h3 className="text-xl font-semibold mt-4 mb-2">5.2 Opt-Out</h3>
      <p className="mb-4">
        You may opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications or by contacting us directly.
      </p>

      <h2 className={`${themeClasses.subheading}`}>6. Third-Party Services</h2>

      <p className="mb-4">
        Our platform may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
      </p>

      <h2 className={`${themeClasses.subheading}`}>7. Children's Privacy</h2>

      <p className="mb-4">
        Our services are not intended for use by individuals under the age of 13. We do not knowingly collect personally identifiable information from children under 13.
      </p>

      <h2 className={`${themeClasses.subheading}`}>8. International Data Transfers</h2>

      <p className="mb-4">
        Your information may be transferred to—and maintained on—computers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ from those of your jurisdiction.
      </p>

      <h2 className={`${themeClasses.subheading}`}>9. Changes to This Privacy Policy</h2>

      <p className="mb-4">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date below.
      </p>

      <h2 className={`${themeClasses.subheading}`}>10. Contact Us</h2>

      <p className="mb-4">
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:privacy@k8sman.io" className={themeClasses.link}>privacy@k8sman.io</a>.
      </p>

      <p className="mt-8">
        Last updated: 10-08-2024
      </p>
    </div>
  );
};

export default PrivacyPolicy;
