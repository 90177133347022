import React, { useState } from 'react';
import ThemeContext from './ThemeContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ComingSoon from './pages/ComingSoon';

const App = () => {
  const [darkMode, setDarkMode] = useState(true);

  const pageStyle = {
    backgroundColor: darkMode ? '#0d0d0d' : '#ffffff',
    backgroundImage: darkMode
      ? `
        radial-gradient(circle at 0% 0%, rgba(236, 72, 153, 0.4) 0%, rgba(200, 72, 153, 0.3) 0%, transparent 10%),
        radial-gradient(circle at 30% 10%, rgba(99, 102, 241, 0.3) 0%, rgba(99, 102, 241, 0.15) 0%, transparent 10%),
        radial-gradient(circle at 80% 10%, rgba(99, 102, 241, 0.3) 0%, rgba(99, 102, 241, 0.15) 0%, transparent 8%),
        radial-gradient(circle at 90% 30%, rgba(72, 201, 176, 0.3) 0%, rgba(72, 201, 176, 0.15) 10%, transparent 30%),
        radial-gradient(circle at 0% 80%, rgba(99, 102, 241, 0.2) 0%, rgba(99, 102, 241, 0.15) 0%, transparent 0%),
        radial-gradient(circle at 80% 80%, rgba(236, 72, 153, 0.3) 0%, rgba(236, 72, 153, 0.15) 0%, transparent 20%)
      `
      : `
        radial-gradient(circle at 0% 0%, rgba(99, 102, 241, 0.4) 0%, rgba(150, 102, 241, 0.3) 0%, transparent 10%),
        radial-gradient(circle at 30% 10%, rgba(236, 72, 153, 0.3) 0%, rgba(236, 72, 153, 0.15) 0%, transparent 10%),
        radial-gradient(circle at 80% 10%, rgba(72, 201, 176, 0.3) 0%, rgba(72, 201, 176, 0.15) 0%, transparent 8%),
        radial-gradient(circle at 90% 30%, rgba(99, 102, 241, 0.3) 0%, rgba(99, 102, 241, 0.15) 10%, transparent 30%),
        radial-gradient(circle at 0% 80%, rgba(236, 72, 153, 0.2) 0%, rgba(236, 72, 153, 0.15) 0%, transparent 0%),
        radial-gradient(circle at 80% 80%, rgba(72, 201, 176, 0.3) 0%, rgba(72, 201, 176, 0.15) 0%, transparent 20%)
      `,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };
  

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <div
        className={`${
          darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
        } min-h-screen`}
        style={pageStyle}
      >
        <Router>
          <Routes>
            <Route path="/draft" element={<Home />} />
            <Route path="/" element={<ComingSoon />} />
          </Routes>
        </Router>
      </div>
    </ThemeContext.Provider>
  );
};

export default App;