// src/components/Modal.js

import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';

const Modal = ({ isOpen, onClose, content }) => {
  const { darkMode } = useContext(ThemeContext);

  if (!isOpen) return null;

  const themeClasses = {
    bgOverlay: 'bg-black bg-opacity-50', // Fundo semi-transparente
    bgModal: darkMode ? 'bg-gray-800' : 'bg-white',
    text: darkMode ? 'text-gray-300' : 'text-gray-800',
    closeButton: darkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-600 hover:text-gray-800',
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${themeClasses.bgOverlay}`}>
      <div className={`relative w-full max-w-3xl mx-4 md:mx-auto my-8 p-6 rounded-lg shadow-lg ${themeClasses.bgModal}`}>
        <button
          onClick={onClose}
          className={`absolute top-4 right-4 focus:outline-none ${themeClasses.closeButton}`}
          aria-label="Close Modal"
        >
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M10 8.586l4.95-4.95a1 1 0 111.414 1.414L11.414 10l4.95 4.95a1 1 0 01-1.414 1.414L10 11.414l-4.95 4.95a1 1 0 01-1.414-1.414L8.586 10 3.636 5.05a1 1 0 011.414-1.414L10 8.586z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <div className={`overflow-y-auto max-h-[80vh] ${themeClasses.text}`}>
          {content}
        </div>
      </div>
    </div>
  );
};

export default Modal;
