import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';

const TermsOfService = () => {
  const { darkMode } = useContext(ThemeContext);

  const themeClasses = {
    heading: 'text-3xl font-bold mb-6',
    subheading: 'text-2xl font-semibold mt-6 mb-4',
    text: darkMode ? 'text-gray-300' : 'text-gray-700',
    link: 'text-indigo-500 hover:text-indigo-600',
  };

  return (
    <div className={`px-4 md:px-0 ${themeClasses.text}`}>
      <h1 className={`${themeClasses.heading}`}>Terms of Service</h1>

      <p className="mb-4">
        Welcome to K8sman. These Terms of Service ("Terms") govern your access to and use of our platform, products, and services ("Services"). Please read these Terms carefully before using our Services.
      </p>

      <h2 className={`${themeClasses.subheading}`}>1. Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you may not use our Services.
      </p>

      <h2 className={`${themeClasses.subheading}`}>2. Eligibility</h2>
      <p className="mb-4">
        You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you meet this requirement.
      </p>

      <h2 className={`${themeClasses.subheading}`}>3. Account Registration</h2>
      <p className="mb-4">
        To access certain features of our Services, you may be required to register for an account. You agree to provide accurate and complete information during registration and to keep your account information up-to-date.
      </p>

      <h2 className={`${themeClasses.subheading}`}>4. User Responsibilities</h2>
      <p className="mb-4">
        You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
      </p>

      <h2 className={`${themeClasses.subheading}`}>5. Acceptable Use</h2>
      <p className="mb-4">
        You agree not to use our Services for any unlawful or prohibited purposes, including but not limited to:
      </p>
      <ul className="list-disc pl-5 mb-4">
        <li>Engaging in any illegal activities.</li>
        <li>Infringing upon the rights of others.</li>
        <li>Disrupting or interfering with the security or performance of our Services.</li>
        <li>Accessing or attempting to access another user's account without authorization.</li>
      </ul>

      <h2 className={`${themeClasses.subheading}`}>6. Intellectual Property</h2>
      <p className="mb-4">
        All content, trademarks, and data on our Services, including but not limited to software, databases, text, graphics, icons, and hyperlinks, are the property of or licensed to K8sman and are protected by intellectual property laws.
      </p>

      <h2 className={`${themeClasses.subheading}`}>7. Subscription and Fees</h2>
      <p className="mb-4">
        Certain Services may require payment of fees. You agree to pay all applicable fees and charges for your use of the Services. All payments are non-refundable unless otherwise stated.
      </p>

      <h2 className={`${themeClasses.subheading}`}>8. Termination</h2>
      <p className="mb-4">
        We reserve the right to suspend or terminate your access to our Services at any time, with or without cause or notice, including if we believe you have violated these Terms.
      </p>

      <h2 className={`${themeClasses.subheading}`}>9. Disclaimer of Warranties</h2>
      <p className="mb-4">
        Our Services are provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that the Services will be uninterrupted or error-free.
      </p>

      <h2 className={`${themeClasses.subheading}`}>10. Limitation of Liability</h2>
      <p className="mb-4">
        In no event shall K8sman be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Services.
      </p>

      <h2 className={`${themeClasses.subheading}`}>11. Indemnification</h2>
      <p className="mb-4">
        You agree to indemnify and hold harmless K8sman and its affiliates from any claims, losses, damages, liabilities, including legal fees, arising out of your use or misuse of the Services.
      </p>

      <h2 className={`${themeClasses.subheading}`}>12. Governing Law</h2>
      <p className="mb-4">
        These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which K8sman is established, without regard to its conflict of law provisions.
      </p>

      <h2 className={`${themeClasses.subheading}`}>13. Changes to the Terms</h2>
      <p className="mb-4">
        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Changes become effective immediately upon posting.
      </p>

      <h2 className={`${themeClasses.subheading}`}>14. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms, please contact us at <a href="mailto:support@k8sman.io" className={themeClasses.link}>support@k8sman.io</a>.
      </p>

      <p className="mt-8">
        Last updated: 10-08-2024
      </p>
    </div>
  );
};

export default TermsOfService;
