import React, { useState, useContext, useEffect } from 'react';
import ThemeContext from '../ThemeContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const navItems = [
    { name: 'Platform', href: '#platform' },
    { name: 'Integrations', href: '#integration' },
    { name: 'Documentation', href: '#docs' },
    { name: 'Contact', href: '#contact' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (event) => {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href').slice(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const headerOffset = 70;
      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }
    setIsOpen(false);
  };

  const scrollToTop = (event) => {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const themeClasses = {
    headerBg: darkMode ? 'bg-gray-900' : 'bg-white',
    text: darkMode ? 'text-white' : 'text-gray-800',
    border: darkMode ? 'border-gray-700' : 'border-gray-200',
    navItemHover: 'hover:text-indigo-500',
    menuIcon: darkMode ? 'text-white' : 'text-gray-800',
    buttonBg: 'bg-indigo-600 hover:bg-indigo-700',
    buttonText: 'text-white',
    mobileMenuBg: darkMode ? 'bg-gray-800' : 'bg-white',
  };

  return (
    <header
      className={`fixed w-full z-20 py-4 transition-colors duration-300 lg:px-24 ${
        scrolled ? `${themeClasses.headerBg} border-b ${themeClasses.border}` : 'bg-transparent'
      }`}
    >
      <nav className="container mx-auto px-5 flex items-center justify-between">
        <a href="#" onClick={scrollToTop} className={`text-2xl font-bold ${themeClasses.text}`}>
          K8sman
        </a>
        <div className="flex items-center">
          {/* Desktop Menu */}
          <div className="hidden lg:flex space-x-8">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                onClick={scrollToSection}
                className={`text-base font-medium ${themeClasses.text} ${themeClasses.navItemHover}`}
              >
                {item.name}
              </a>
            ))}
          </div>
          <a
            href="/platform"
            className={`ml-4 hidden lg:inline-block px-4 py-2 rounded ${themeClasses.buttonBg} ${themeClasses.buttonText} text-sm font-medium`}
          >
            Access Platform
          </a>
          {/* Hamburger Menu Icon */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="ml-4 lg:hidden focus:outline-none"
            aria-label="Toggle Menu"
          >
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-6 h-6 ${themeClasses.menuIcon}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* Ícone de Fechar */}
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-6 h-6 ${themeClasses.menuIcon}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {/* Ícone de Menu */}
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </button>
        </div>
      </nav>
      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 z-10 transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out lg:hidden flex`}
      >
        {/* Overlay */}
        <div
          className="flex-1 h-full bg-black opacity-50"
          onClick={() => setIsOpen(false)}
        ></div>
        {/* Menu Content */}
        <div className={`w-3/4 h-full ${themeClasses.mobileMenuBg} p-5`}>
          <button
            onClick={() => setIsOpen(false)}
            className="mb-8 focus:outline-none"
            aria-label="Close Menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`w-6 h-6 ${themeClasses.menuIcon}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {/* Ícone de Fechar */}
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <nav className="flex flex-col space-y-4">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                onClick={scrollToSection}
                className={`text-lg font-medium ${themeClasses.text} ${themeClasses.navItemHover}`}
              >
                {item.name}
              </a>
            ))}
            <a
              href="/platform"
              className={`mt-4 px-4 py-2 rounded ${themeClasses.buttonBg} ${themeClasses.buttonText} text-base font-medium`}
            >
              Access Platform
            </a>
          </nav>
        </div>
      </div>
      {/* Floating Theme Toggle */}
      <div
        className="fixed bottom-10 right-10 bg-indigo-600 rounded-full p-3 shadow-lg cursor-pointer z-30"
        onClick={() => setDarkMode(!darkMode)}
        aria-label="Toggle Dark Mode"
      >
        {darkMode ? (
          // Novo ícone de luz
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="5" />
            <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
          </svg>
        ) : (
          // Ícone da Lua permanece o mesmo
          <svg
            className="w-6 h-6 text-gray-100"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            {/* Ícone da Lua */}
            <path d="M17.293 13.293a8 8 0 11-10.586-10.586 8 8 0 0010.586 10.586z" />
          </svg>
        )}
      </div>
    </header>
  );
};

export default Header;
