import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';

const Docs = () => {
  const { darkMode } = useContext(ThemeContext);

  const themeClasses = {
    text: darkMode ? 'text-white' : 'text-gray-800',
    subText: darkMode ? 'text-gray-300' : 'text-gray-600',
    cardBg: darkMode ? 'bg-gray-800' : 'bg-white',
    link: 'text-indigo-500 hover:text-indigo-600',
    buttonBg: 'bg-indigo-600 hover:bg-indigo-700 text-white',
  };

  return (
    <section id="docs" className="py-24 lg:px-24">
      <div className="container mx-auto px-5 flex flex-col items-center">
        <div className="text-center mb-16">
          <h2 className={`text-4xl font-bold mb-4 ${themeClasses.text}`}>Comprehensive Documentation</h2>
          <p className={`text-xl ${themeClasses.subText}`}>
            Explore our detailed guides and API references to maximize your use of K8sman.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {/* Documentation Cards */}
          <div className="md:w-1/3 w-full p-4">
            <div className={`p-6 rounded-lg shadow-lg ${themeClasses.cardBg} h-full`}>
              <h2 className={`text-xl font-semibold mb-2 ${themeClasses.text}`}>Getting Started</h2>
              <p className={`text-base mb-4 ${themeClasses.subText}`}>
                Learn how to set up and configure K8sman for your organization.
              </p>
              <a href="/docs/getting-started" className={`inline-flex items-center ${themeClasses.link}`}>
                Read More
                <svg
                  className="w-4 h-4 ml-2"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="md:w-1/3 w-full p-4">
            <div className={`p-6 rounded-lg shadow-lg ${themeClasses.cardBg} h-full`}>
              <h2 className={`text-xl font-semibold mb-2 ${themeClasses.text}`}>API Reference</h2>
              <p className={`text-base mb-4 ${themeClasses.subText}`}>
                Dive into our API documentation to integrate K8sman with your existing workflows.
              </p>
              <a href="/docs/api-reference" className={`inline-flex items-center ${themeClasses.link}`}>
                Read More
                <svg
                  className="w-4 h-4 ml-2"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="md:w-1/3 w-full p-4">
            <div className={`p-6 rounded-lg shadow-lg ${themeClasses.cardBg} h-full`}>
              <h2 className={`text-xl font-semibold mb-2 ${themeClasses.text}`}>Best Practices</h2>
              <p className={`text-base mb-4 ${themeClasses.subText}`}>
                Follow our recommended practices to ensure optimal performance and security.
              </p>
              <a href="/docs/best-practices" className={`inline-flex items-center ${themeClasses.link}`}>
                Read More
                <svg
                  className="w-4 h-4 ml-2"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="text-center mt-12">
          <a href="/docs" className={`px-8 py-3 rounded-lg text-lg font-medium ${themeClasses.buttonBg}`}>
            Visit Documentation
          </a>
        </div>
      </div>
    </section>
  );
};

export default Docs;
