// src/components/Footer.js

import React, { useState, useContext } from 'react';
import ThemeContext from '../ThemeContext';
import Modal from './Modal';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';

const Footer = () => {
  const { darkMode } = useContext(ThemeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const themeClasses = {
    bg: darkMode ? 'bg-gray-900' : 'bg-gray-100',
    text: darkMode ? 'text-gray-300' : 'text-gray-600',
    link: 'hover:text-indigo-500',
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <>
      <footer className={`${themeClasses.bg} py-8 lg:px-24`}>
        <div className="container mx-auto px-5 flex flex-col sm:flex-row justify-between items-center">
          <p className={`text-sm ${themeClasses.text} mb-4 sm:mb-0`}>
            © 2024 K8sman.io — All Rights Reserved
          </p>
          <div className="flex space-x-6">
            <button
              onClick={() => openModal(<PrivacyPolicy />)}
              className={`text-sm focus:outline-none ${themeClasses.text} ${themeClasses.link}`}
            >
              Privacy Policy
            </button>
            <button
              onClick={() => openModal(<TermsOfService />)}
              className={`text-sm focus:outline-none ${themeClasses.text} ${themeClasses.link}`}
            >
              Terms of Service
            </button>
            <a href="#faq" className={`text-sm ${themeClasses.text} ${themeClasses.link}`}>
              FAQ
            </a>
            <a href="#contact" className={`text-sm ${themeClasses.text} ${themeClasses.link}`}>
              Contact Us
            </a>
          </div>
        </div>
      </footer>
      <Modal isOpen={isModalOpen} onClose={closeModal} content={modalContent} />
    </>
  );
};

export default Footer;
