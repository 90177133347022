import React, { useState, useContext } from 'react';
import ThemeContext from '../ThemeContext';
import Modal from './Modal';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';

const Hero = () => {
  const { darkMode } = useContext(ThemeContext); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);


  const themeClasses = {
    text: darkMode ? 'text-white' : 'text-gray-800',
    subText: darkMode ? 'text-gray-300' : 'text-gray-600',
    buttonPrimary: 'bg-indigo-600 hover:bg-indigo-700 text-white',
    inputBg: darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-800',
    border: darkMode ? 'border-gray-700' : 'border-gray-300',
    focusRing: 'focus:ring-indigo-500',
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  return (
    <section className={`relative  lg:px-24`}>
      <div className="container mx-auto px-5 py-10 pt-36 flex flex-col md:flex-row items-center">
        {/* Left Side Content */}
        <div className="flex flex-col md:w-1/2 md:pr-12 mb-16 md:mb-0">
          <h1 className={`text-5xl font-bold leading-tight mb-6 ${themeClasses.text}`}>
            Empower Your Kubernetes Operations with K8sman
          </h1>
          <p className={`text-xl mb-8 ${themeClasses.subText}`}>
            K8sman simplifies Kubernetes management by providing centralized control, enhanced security, and seamless integrations. Focus on innovation, not infrastructure.
          </p>
          <div className="flex">
            <a
              href="#platform"
              className={`px-6 py-3 rounded-lg text-lg font-medium ${themeClasses.buttonPrimary} mr-4`}
            >
              Learn More
            </a>
            <a
              href="#docs"
              className={`px-6 py-3 rounded-lg text-lg font-medium border ${themeClasses.border} ${themeClasses.text} hover:bg-gray-100`}
            >
              Explore Docs
            </a>
          </div>
        </div>

        {/* Registration Form */}
        <div className="md:w-1/2 w-full">
          <div className={`p-8 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
            <h2 className={`text-2xl font-semibold mb-6 ${themeClasses.text}`}>Create Your Account</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="fullName" className={`block text-sm mb-1 ${themeClasses.subText}`}>
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  className={`w-full px-4 py-2 rounded-lg border ${themeClasses.border} ${themeClasses.inputBg} focus:outline-none focus:ring-2 ${themeClasses.focusRing}`}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className={`block text-sm mb-1 ${themeClasses.subText}`}>
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`w-full px-4 py-2 rounded-lg border ${themeClasses.border} ${themeClasses.inputBg} focus:outline-none focus:ring-2 ${themeClasses.focusRing}`}
                />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className={`block text-sm mb-1 ${themeClasses.subText}`}>
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className={`w-full px-4 py-2 rounded-lg border ${themeClasses.border} ${themeClasses.inputBg} focus:outline-none focus:ring-2 ${themeClasses.focusRing}`}
                />
              </div>
              <button
                type="submit"
                className={`w-full py-3 rounded-lg font-medium ${themeClasses.buttonPrimary}`}
              >
                Register
              </button>
              <p className={`text-sm mt-4 ${themeClasses.subText}`}>
                By creating an account, you agree to our{' '}
                <a onClick={() => openModal(<TermsOfService />)} href="#" className="text-indigo-500 hover:text-indigo-600">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a onClick={() => openModal(<PrivacyPolicy />)} href="#" className="text-indigo-500 hover:text-indigo-600">
                  Privacy Policy
                </a>.
              </p>
            </form>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} content={modalContent} />
    </section>
  );
};

export default Hero;
