import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';

const Faq = () => {
  const { darkMode } = useContext(ThemeContext);

  const themeClasses = {
    text: darkMode ? 'text-white' : 'text-gray-800',
    subText: darkMode ? 'text-gray-300' : 'text-gray-600',
    bg: darkMode ? 'bg-gray-900' : 'bg-white',
    border: darkMode ? 'border-gray-700' : 'border-gray-200',
    highlight: 'text-indigo-500',
  };

  const faqs = [
    {
      question: 'Does K8sman support multi-cloud environments?',
      answer:
        'Yes, K8sman is designed to work seamlessly across multiple cloud platforms, making it easy to manage your Kubernetes clusters anywhere.',
    },
    {
      question: 'Can I integrate K8sman with third-party monitoring tools?',
      answer:
        'Absolutely. K8sman integrates with popular monitoring tools like Prometheus, Datadog, and New Relic for comprehensive observability.',
    },
    {
      question: 'How can I get started with K8sman?',
      answer:
        'Getting started is easy! Just visit our documentation page for a detailed guide on installation and configuration.',
    },
    {
      question: 'Is there support for Single Sign-On (SSO)?',
      answer:
        'Yes, K8sman supports SSO integration, allowing you to securely and easily manage user access across your Kubernetes clusters.',
    },
    {
      question: 'Can I automate scaling with K8sman?',
      answer:
        'Yes, K8sman provides automation capabilities to help you scale your Kubernetes clusters efficiently based on resource demand.',
    },
    {
      question: 'What kind of support is available?',
      answer:
        'We offer 24/7 support to help you manage and troubleshoot your Kubernetes environment effectively.',
    },
    {
      question: 'How does K8sman ensure security?',
      answer:
        'K8sman implements best-in-class security practices, including RBAC, network policies, and secure access controls to ensure the safety of your clusters.',
    },
    {
      question: 'Can I monitor resource usage with K8sman?',
      answer:
        'Yes, K8sman provides detailed metrics and analytics to help you monitor resource usage across your Kubernetes environment.',
    },
    {
      question: 'Does K8sman offer audit logs?',
      answer:
        'Yes, K8sman maintains comprehensive audit logs to track all changes and activities, giving you full visibility into your infrastructure.',
    },
  ];

  
  return (
    <section id="faq" className="py-24 lg:px-24">
      <div className="container mx-auto px-5 flex flex-col items-center">
        <div className="text-center mb-16">
          <h2 className={`text-4xl font-bold mb-4 ${themeClasses.text}`}>Frequently Asked Questions</h2>
          <p className={`text-xl ${themeClasses.subText}`}>
            If you can't find what you're looking for, our support team is ready to assist you.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          {faqs.map((faq, index) => (
            <div key={index} className={`border-b pb-6 ${themeClasses.border}`}>
              <h3 className={`text-2xl font-semibold mb-2 ${themeClasses.text}`}>{faq.question}</h3>
              <p className={`text-base ${themeClasses.subText}`}>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
