import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';
import newrelic from '../assets/images/newrelic.png';
import datadog from '../assets/images/datadog.svg';
import opentelemetry from '../assets/images/opentelemetry.svg';
import prometheus from '../assets/images/prometheus.png';

const Integration = () => {
  const { darkMode } = useContext(ThemeContext);

  const themeClasses = {
    text: darkMode ? 'text-white' : 'text-gray-800',
    subText: darkMode ? 'text-gray-300' : 'text-gray-600',
    cardBg: darkMode ? 'bg-gray-800' : 'bg-white',
  };

  const integrations = [
    {
      title: 'Prometheus',
      category: 'Monitoring',
      description: 'Leverage Prometheus for robust monitoring and alerting capabilities.',
      imageUrl: prometheus,
    },
    {
      title: 'New Relic',
      category: 'Analytics',
      description: 'Integrate with New Relic for real-time insights and analytics.',
      imageUrl: newrelic,
    },
    {
      title: 'Datadog',
      category: 'Observability',
      description: 'Utilize Datadog for full-stack observability and security.',
      imageUrl: datadog,
    },
    {
      title: 'OpenTelemetry',
      category: 'Tracing',
      description: 'Implement OpenTelemetry for unified metrics and tracing.',
      imageUrl: opentelemetry,
    },
  ];

  return (
    <section id="integration" className="py-24 lg:px-24">
      <div className="container mx-auto px-5 flex flex-col items-center">
        {/* Section Header */}
        <div className="text-center mb-16 max-w-2xl">
          <h2 className={`text-4xl font-bold mb-6 ${themeClasses.text}`}>Seamless Integrations</h2>
          <p className={`text-xl ${themeClasses.subText}`}>
            K8sman integrates effortlessly with industry-leading tools to enhance your Kubernetes ecosystem.
          </p>
        </div>

        {/* Integration Cards */}
        <div className="flex flex-wrap -m-4">
          {integrations.map((integration, index) => (
            <div key={index} className="md:w-1/2 lg:w-1/4 w-full p-4">
              <div className={`p-6 rounded-lg shadow-lg ${themeClasses.cardBg} h-full flex flex-col`}>
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={integration.imageUrl}
                  alt={integration.title}
                  style={{
                    filter: darkMode ? 'grayscale(1) brightness(5)' : 'none',
                    opacity: darkMode ? 0.8 : 1,
                  }}
                />
                <h3 className="text-sm font-medium text-indigo-500 mb-2">{integration.category}</h3>
                <h2 className={`text-xl font-semibold mb-2 ${themeClasses.text}`}>{integration.title}</h2>
                <p className={`text-base flex-grow ${themeClasses.subText}`}>{integration.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Integration;
