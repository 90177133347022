import React, { useContext } from 'react';
import ThemeContext from '../ThemeContext';
import k8smanPng from '../assets/images/k8sman_png.png';

const features = [
  {
    title: 'Granular Access Control',
    description: 'Manage permissions with precision, ensuring each team member has the appropriate access.',
    iconPath: 'M7.864 4.243A7.5 7.5 0 0 1 19.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 0 0 4.5 10.5a7.464 7.464 0 0 1-1.15 3.993m1.989 3.559A11.209 11.209 0 0 0 8.25 10.5a3.75 3.75 0 1 1 7.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 0 1-3.6 9.75m6.633-4.596a18.666 18.666 0 0 1-2.485 5.33',
  },
  {
    title: 'Enhanced Visibility',
    description: 'Gain full insight into cluster activities with comprehensive auditing and logging.',
    iconPath: 'M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99',
  },
  {
    title: 'Secure External Collaboration',
    description: 'Provide temporary, secure access to external teams without compromising your infrastructure.',
    iconPath: 'M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z',
  },
  {
    title: 'Simplified Operations',
    description: 'Eliminate the need for complex VPN setups with our streamlined access solutions.',
    iconPath: 'M22 12h-4l-3 9L9 3l-3 9H2',
  },
];

const Platform = () => {
  const { darkMode } = useContext(ThemeContext);

  const themeClasses = {
    text: darkMode ? 'text-white' : 'text-gray-800',
    subText: darkMode ? 'text-gray-300' : 'text-gray-600',
    iconBg: darkMode ? 'bg-indigo-600' : 'bg-indigo-200',
    iconText: darkMode ? 'text-white' : 'text-indigo-800',
    buttonBg: darkMode ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-indigo-600 hover:bg-indigo-700',
    buttonText: 'text-white',
  };

  return (
    <section id="platform" className="py-16 lg:py-24 lg:px-24">
      <div className="container mx-auto px-5 flex flex-col md:flex-row items-center">
        {/* Left Side Image */}
        <div className="md:w-1/2 w-full mb-12 md:mb-0">
          <img src={k8smanPng} alt="Platform illustration" className="w-full h-auto" />
        </div>
        {/* Right Side Content */}
        <div className="md:w-1/2 w-full md:pl-12">
          <h2 className={`text-4xl font-bold mb-6 ${themeClasses.text}`}>Elevate Your Kubernetes Experience</h2>
          <p className={`text-xl mb-8 ${themeClasses.subText}`}>
            K8sman revolutionizes Kubernetes management with a suite of tools designed for efficiency, security, and scalability. Our platform empowers your DevOps teams to operate with confidence.
          </p>
          <a
            href="/platform"
            className={`px-6 py-3 rounded-lg text-lg font-medium ${themeClasses.buttonBg} ${themeClasses.buttonText}`}
          >
            Access Platform
          </a>
        </div>
      </div>

      <div className="container mx-auto px-5 mt-16">
        <dl className="grid md:grid-cols-2 gap-12">
          {features.map((feature, index) => (
            <div key={index} className="flex">
              <div
                className={`flex-shrink-0 h-12 w-12 rounded-lg flex items-center justify-center ${themeClasses.iconBg}`}
              >
                <svg
                  className={`h-6 w-6 ${themeClasses.iconText}`}
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d={feature.iconPath} />
                </svg>
              </div>
              <div className="ml-4">
                <dt className={`text-xl font-semibold ${themeClasses.text}`}>{feature.title}</dt>
                <dd className={`mt-2 text-base ${themeClasses.subText}`}>{feature.description}</dd>
              </div>
            </div>
          ))}
        </dl>
      </div>
    </section>
  );
};

export default Platform;
