// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Platform from '../components/Platform';
import Integration from '../components/Integration';
import Faq from '../components/Faq';
import Docs from '../components/Docs';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <div>
        <Header />
        <Hero />
        <Platform />
        <Integration />
        <Docs />
        <Faq />
        <Contact />
        <Footer/>
        </div>
    );
};

export default Home;
